/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.carousel .headline-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  width: -moz-max-content;
  width: max-content; }
  .carousel .headline-link .arrow-right {
    display: inline-block;
    margin-left: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.75rem; }
  .carousel .headline-link:hover {
    color: var(--color-primary);
    text-decoration: underline; }
  .carousel .headline-link:focus {
    box-shadow: none; }

.carousel .carousel-header {
  margin-bottom: 2.5rem; }
  @media (min-width: 769px) {
    .carousel .carousel-header {
      margin-bottom: 3rem; } }

.carousel .carousel-title {
  margin-bottom: 0.375rem; }
  @media (min-width: 769px) {
    .carousel .carousel-title {
      margin-bottom: 1.125rem; } }
